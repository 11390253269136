export const URLS = {
  METRICS: '/metrics',
  DASHBOARDS: '/dashboards',
  ACCESSES: '/accesses',
  ACTIVITIES: '/activities',
  BUSINESSES: '/businesses',
  BUSERS: '/busers',
  CHECKINS: '/checkins',
  USERS: '/users',
  SITES: '/sites',
  SUBSCRIPTIONS: '/subscriptions',
  DISCOUNTS: '/discounts',
  // GOLF_SIMULATOR_CALCULATOR: '/golf-simulator-calculator'
  MEMBERSHIP_PICTURES_APPROVE: '/membership-pictures-approve'
};


export const ActivityTypeRefs = {
  Golf: 'golf',
  GolfSimulator: 'golfsimulator'
};


export const CHANGES_MESSAGE = 'You have unsaved changes, are you sure?';