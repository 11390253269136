import { createRoot } from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';
import './index.css';
import store from './store';
import App from './pages/App';
import ErrorPage from './pages/ErrorPage';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import history from './history';
import * as API from './lib/api';

if (
  process.env.REACT_APP_SENTRY_DSN
  && process.env.REACT_APP_SENTRY_ENV
  && process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE
  });
}

const rootElement = document.getElementById('root');

ReactModal.setAppElement(rootElement);

const root = createRoot(rootElement);

const onRedirectCallback = (appState, navigate) => {
  // If using a Hash Router, you need to use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  // window.history.replaceState({}, document.title, window.location.pathname);
  navigate((appState && appState.returnTo) || window.location.pathname);
  // history.replace((appState && appState.returnTo) || window.location.pathname);
};

function Auth0AccessTokenHelper() {
  const { getAccessTokenSilently } = useAuth0();
  API.setgetAccessTokenSilently(getAccessTokenSilently);
  return null;
}

function Root() {
  const navigate = useNavigate();
  
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin + '/callback/auth'}
      onRedirectCallback={(appState) => onRedirectCallback(appState, navigate)}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Auth0AccessTokenHelper />
      <Provider store={store}>
        <Toaster
          toastOptions={{
            // duration: 6000,
            success: {
              iconTheme: {
                primary: '#10B981'
              }
            }
          }}
        />
        <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
          <App />
        </Sentry.ErrorBoundary>
      </Provider>
    </Auth0Provider>
  );
}

root.render((
  <BrowserRouter>
    <Root />
  </BrowserRouter>
));

function handleRegistration(registration) {
  try {
    if (registration) {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
}

serviceWorkerRegistration.register({
  onSuccess: registration => {
    function checkForUpdate() {
      registration.update();
    }

    setInterval(checkForUpdate, 20 * 60 * 1000);
    checkForUpdate();

    handleRegistration(registration);
  },
  onUpdate: registration => {
    handleRegistration(registration);
  }
});

reportWebVitals();

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      handleRegistration(registration);
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  });
}